import React from "react";
import config from "../../../config"
import Flex from "../../ui/Flex";
import {  fonts } from "../../../providers/theme/theme";
import { TitleText, BodyText } from "../../ui/Text";

import TwitterIcon from "../../svgs/TwitterIcon";
import DiscordIcon from "../../svgs/DiscordIcon";
import TelegramIcon from "../../svgs/TelegramIcon";
import { PrimaryButton } from "../../ui/Button";
import { MAX_PAGE_WIDTH, MIN_PAGE_MARGIN } from "../../../utils/constants";
import styled from "styled-components";
import IconFeiFlat from "../../svgs/IconFeiFlat";
import IconTribeFlat from "../../svgs/IconTribeFlat";
import IconGrantsFlat from "../../svgs/IconGrantsFlat";
import IconTally from "../../svgs/IconTally";
import { openLink } from "../../../utils/analytics";



export default function FooterBlock() {

    return (
        <Flex {...styles.Container}>
            <Flex {...styles.ContentContainer}>
                <Flex 
                    {...styles.SectionContainer}
                    marginTop={["0px", "-16px"]}
                >
                    <TitleText
                        text={"Join The Tribe"}
                        {...styles.JoinTribeTitleText}
                    />
                    <Flex {...styles.SolidSeparatorLine}/>
                    <PrimaryButton
                        text={"Discord"}
                        onClick={() => { openLink(config.links.discord, "JOIN_OUR_DISCORD_FOOTER") }}
                        {...styles.JoinDiscordButton}
                        gradientKind={"rainbow"}
                        hoverRainbowKind={"rainbow"}
                    />
                </Flex>
                <Flex {...styles.SectionContainer}>
                    <TitleText
                        text={"Resources"}
                        {...styles.SectionHeaderText}
                    />
                    <GradientLine
                        width={"85px"}
                    />
                    {
                        resourcesLinks.map(({text, url, trackingName}, i) => {
                            return (
                                <BodyText
                                    text={text}
                                    onClick={() => { openLink(url, `${trackingName}_FOOTER`) }}
                                    {...styles.LinkText}
                                    key={i}
                                />
                            );
                        })
                    }
                </Flex>
                <Flex {...styles.SectionContainer}>
                    <TitleText
                        text={"Governance"}
                        {...styles.SectionHeaderText}
                    />
                    <GradientLine
                        width={"92px"}
                    />
                    {
                        governanceLinks.map(({text, url, trackingName}, i) => {
                            return (
                                <BodyText
                                    text={text}
                                    onClick={() => { openLink(url, `${trackingName}_FOOTER`) }}
                                    {...styles.LinkText}
                                    key={i}
                                />
                            );
                        })
                    }
                </Flex>
                <Flex {...styles.SectionContainer}>
                    <TitleText
                        text={"Fine Print"}
                        {...styles.SectionHeaderText}
                    />
                    <GradientLine
                        width={"92px"}
                    />
                    {
                        finePrintLinks.map(({text, url, trackingName}, i) => {
                            return (
                                <BodyText
                                    text={text}
                                    onClick={() => { openLink(url, `${trackingName}_FOOTER`) }}
                                    {...styles.LinkText}
                                    key={i}
                                />
                            );
                        })
                    }
                </Flex>
                <Flex {...styles.SectionContainer}>
                    <Flex alignItems={"flex-end"}>
                        <IconFeiFlat height={"13.3px"} fill={"#729388"}/>
                        <TitleText
                            text={"Community"}
                            {...styles.SectionHeaderText}
                        />
                    </Flex>

                    <GradientLine
                        width={"120px"}
                    />
                    <Flex {...styles.IconRow} marginTop={["15px", "10px"]} >
                        {
                            communityLinks.slice(0,3).map(({text, url, IconComponent, trackingName}, i) => {
                                return (
                                    <Flex
                                        {...styles.CommunityIconContainer}
                                        key={i}
                                        onClick={() => { openLink(url, `${trackingName}_FOOTER`) }}
                                    >
                                        {IconComponent}
                                    </Flex>
                                );
                            })
                        }
                    </Flex>
                    <Flex {...styles.IconRow}>
                        {
                            communityLinks.slice(3,6).map(({text, url, IconComponent, trackingName}, i) => {
                                return (
                                    <Flex
                                        {...styles.CommunityIconContainer}
                                        onClick={() => { openLink(url, `${trackingName}_FOOTER`) }}
                                        key={i}
                                    >
                                        {IconComponent}
                                    </Flex>
                                );
                            })
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export const resourcesLinks = [
    {
        text: "Github",
        url: config.links.github,
        trackingName: "GITHUB",
    },
    {
        text: "Docs",
        url: config.links.docs,
        trackingName: "DOCS",
    },
    {
        text: "Blog",
        url: config.links.medium.base,
        trackingName: "BLOG",
    },
];
export const governanceLinks = [
    {
        text: "Snapshot",
        url: config.links.snapshot,
        trackingName: "SNAPSHOT",
    },
    {
        text: "Tally",
        url: config.links.tally,
        trackingName: "TALLY",
    },
    {
        text: "Forum",
        url: config.links.discourse,
        trackingName: "FORUM",
    },
];
export const communityLinks = [
    {
        text: "Twitter",
        url: config.links.twitter,
        trackingName: "TWITTER",
        IconComponent: (
            <TwitterIcon 
                fill={"#F5F5F5"} 
                height={"15.83px"}
                width={"100%"}
                marginLeft={"1px"}
                marginTop={"2px"}
            />
        )
    },
    {
        text: "Discord",
        url: config.links.discord,
        trackingName: "DISCORD",
        IconComponent: (
            <DiscordIcon
                fill={"#F5F5F5"} 
                height={"14"}
                width={"100%"}
                marginLeft={"0.5px"}
            />
        )
    },
    // {
    //     text: "Grants",
    //     url: config.links.grants,
    //     trackingName: "GRANTS",
    //     IconComponent: (
    //         <IconGrantsFlat
    //             fill={"#F5F5F5"} 
    //             height={"19"}
    //             width={"100%"}
    //             marginBottom={"2px"}
    //         />
    //     )
    // },
    // {
    //     text: "Telegram",
    //     url: config.links.telegram,
    //     trackingName: "TELEGRAM",
    //     IconComponent: (
    //         <TelegramIcon
    //             fill={"#F5F5F5"} 
    //             height={"15.83"}
    //             width={"100%"}
    //             marginRight={"2px"}
    //             marginTop={"2px"}
    //         />
    //     )
    // },
    // {
    //     text: "Tally",
    //     url: config.links.tally,
    //     trackingName: "TALLY",
    //     IconComponent: (
    //         <IconTally
    //             fill={"#F5F5F5"} 
    //             height={"15.83"}
    //             width={"100%"}
    //         />
    //     )
    // },
    {
        text: "Forum",
        url: config.links.discourse,
        IconComponent: (
            <IconTribeFlat
                fill={"#F5F5F5"}
                height={"15.83px"}
                width={"100%"}
                marginLeft={"1px"}
            />
        )
    }
];

const finePrintLinks = [
    {
        text: "Terms of Service",
        url: "https://assets.fei.money/docs/fei_terms_of_service_03_18_21.pdf",
        trackingName: "TERMS_OF_SERVICE",
    },
    {
        text: "Cookie Policy",
        url: "https://assets.fei.money/docs/fei_cookie_policy_03_18_21.pdf",
        trackingName: "COOKIE_POLICY",
    },
    {
        text: "Privacy Policy",
        url: "https://assets.fei.money/docs/fei_privacy_policy_03_18_21.pdf",
        trackingName: "PRIVACY_POLICY",
    },
];

const GradientLine = styled(Flex)`
    background: radial-gradient(150.04% 907799.79% at 96.57% -150.04%, #4D6A6F 0%, #91B6AA 62.32%, #C7E0D8 100%);
    height: 0.83px;
    margin-top: 13px;
    margin-bottom: 15px;
`;

const styles = {
    Container: {
        width: "100vw",
        flexDirection: ["column","row"],
        paddingTop: ["50px", "60px"],
        alignItems: ["center", "flex-start"],
        justifyContent: "center",
        marginTop: ["180px", "160px"],
        paddingBottom: "30px",
        backgroundColor: "#F5F5F5",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
        zIndex: 1,
    },
    ContentContainer: {
        maxWidth: MAX_PAGE_WIDTH,
        flex: 1,
        width: ["100%", null],
        paddingLeft: MIN_PAGE_MARGIN,
        paddingRight: MIN_PAGE_MARGIN,
        flexDirection: ["column","row"],
        alignItems: "flex-start",
        justifyContent: ["flex-start", "space-evenly"]
    },
    JoinTribeTitleText: {
        fontFamily: fonts.primaryBold,
        fontSize: ["20px", "25px"],
        lineHeight: 1,
        color: "#4D6A6F",
        letterSpacing: "0.05em",
    },
    SolidSeparatorLine: {
        height: "0.83px",
        width: ["150px", "189px"],
        backgroundColor: "#6B9CAC",
        marginTop: "20px",
    },
    JoinDiscordButton: {
        marginTop: "20px",
        fontFamily: fonts.primaryMedium,
        fontSize: "14px",
        height: "34px",
        paddingBottom: ["2px", "2px"]
    },
    SectionContainer: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginLeft: ["30px", "0px"],
    },
    SectionHeaderText: {
        color: "#729388",
        fontFamily: fonts.barlowSemiBold,
        letterSpacing: "0.05em",
        fontSize: "16.6px",
        lineHeight: 1,
        marginTop: ["50px", "0px"]
    },
    LinkText: {
        fontFamily: fonts.barlowMedium,
        letterSpacing: "0.05em",
        fontSize: "14px",
        lineHeight: 2,
        color: "#4E6B70",
        cursor: "pointer",
        underlineOnHover: true,
    },
    CommunityIconContainer: {
        width: "34px",
        height: "34px",
        borderRadius: "34px",
        backgroundColor: "#76A2AD",
        marginRight: "15px",
        marginBottom: "15px",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
    },
    IconRow: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    }
};