import React from "react";

import Svg from "../ui/Svg";

export default function IconMenu({ width = 26, height = 21, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M25.1653 1.73554H0.867769C0.347108 1.73554 0 1.38843 0 0.867769C0 0.347107 0.347108 0 0.867769 0H25.1653V1.73554ZM0 10.5C0 9.97934 0.347108 9.63223 0.867769 9.63223H25.1653V11.3678H0.867769C0.347108 11.3678 0 11.0207 0 10.5ZM0.867768 21C0.347107 21 0 20.6529 0 20.1322C0 19.6116 0.347107 19.2645 0.867768 19.2645L25.1653 19.2645V21L0.867768 21Z" fill="white"/>
        </Svg>
    );
}