import styled, { css } from "styled-components";

import {
    border,
    color,
    flexbox,
    layout,
    opacity,
    position,
    shadow,
    space,
    typography,
    grid,
    background,
    boxShadow,
} from "styled-system";

import {
    cursor,
    hoverBg,
    hoverBoxShadow,
    hoverColor,
    hoverOpacity,
    overflow,
    overflowY,
    pointerEvents,
    textTransform,
    transform,
    transformOrigin,
    transition,
    backdropFilter,
    animation,
} from "../../providers/theme/customProperties";

const Box = styled.div`
    ${background};
    ${border};
    ${color};
    ${cursor};
    ${flexbox};
    ${grid};
    ${hoverBoxShadow};
    ${layout};
    ${opacity};
    ${overflow};
    ${overflowY};
    ${pointerEvents};
    ${position};
    ${shadow};
    ${space};
    ${textTransform};
    ${transform};
    ${transformOrigin};
    ${transition};
    ${typography};
    ${backdropFilter};
    ${animation};
    ${pointerEvents};
    ${boxShadow};

    box-sizing: border-box;

    @media (pointer: fine) {
        &:hover {
            ${hoverBg};
            ${hoverColor};
            ${hoverOpacity};
            ${hoverBoxShadow};
        }
    }
`;

export default Box;
