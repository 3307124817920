import React, {Fragment, useEffect} from "react";
import { useParams } from "react-router-dom";
import config from "../../config";
import get from "lodash/get";

export default function WhitepaperPage({}) {
    const { language } = useParams();

    const englishWhitepaperLink = get(config, "links.whitepaper.english");
    const whitepaperLink = get(
        config,
        `links.whitepaper.${language}`,
        englishWhitepaperLink
    );

    useEffect(() => {
        window.open(whitepaperLink, "_self");
    }, []);

    return (
        <Fragment/>
    );
}