import React from "react";

import Box from "./Box";

export default React.memo(function Flex({ children, ...props }) {
  return (
    <Box display="flex" {...props}>
      {children}
    </Box>
  );
})
