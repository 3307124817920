import BigNumber from "bignumber.js";

export function withCommas(amount) {
    if (!amount) {
        return;
    }
    if (typeof(amount) === "number") {
        amount = amount.toString();
    }
    
    const amountBN = new BigNumber(amount);
    const threeCommaRegex = /\B(?=(\d{3})+(?!\d))/g;
    if (amountBN.isInteger()) {
        return amount.replace(threeCommaRegex, ",");
    }
    const [integerPart, decimalPart] = amount.split(".");
    const integerPartWithCommas = integerPart.replace(threeCommaRegex, ",");
    return `${integerPartWithCommas}.${decimalPart}`;

}