import React, { useEffect } from "react";
import Flex from "../../ui/Flex";
import HeaderBlock from "./HeaderBlock";
import HeroBlock from "./HeroBlock";
import ValuePropsBlock from "./ValuePropsBlock";
import FooterBlock from "./FooterBlock";
import { initAmplitude, sendAmplitudeData } from "../../../utils/analytics";
import styled from "styled-components";
import LaasBlock from "./LaasBlock";
import { useEthereum } from "../../../providers/ethereum";
import get from "lodash/get";
import BigNumber from "bignumber.js";
import { withCommas } from "../../../utils/numbers";

export default function FeiLanding() {
  useEffect(() => {
    initAmplitude();
    sendAmplitudeData("VIEWED_LANDING_PAGE");
  }, []);

  const { protocolState, loading } = useEthereum();

  const tvlWei = get(protocolState, "COLLATERALIZATION_ORACLE.PCV", "0");
  const tvlWeiBN = new BigNumber(tvlWei);
  const tvlBN = tvlWeiBN.div("10e17").decimalPlaces(0);
  const tvlFormatted = tvlWeiBN.gt(0) ? withCommas(tvlBN.toString()) : "";

  return (
    <SolarPunkGradientBackground {...styles.Container}>
      <HeaderBlock />
      <HeroBlock tvlFormatted={tvlFormatted} />
      <FooterBlock />
    </SolarPunkGradientBackground>
  );
}

export const SolarPunkGradientBackground = styled(Flex)`
    background: linear-gradient(180deg, #266A76 0%, #507C6D 38.89%, #528074 53.66%, #6BA9C3 100%);
    # OTHER GRADIENT VERSIONS
    #background: linear-gradient(0deg, rgba(111, 178, 216, 0.8) 0%, rgba(88, 103, 112, 0) 39.9%), linear-gradient(0deg, rgba(144, 204, 122, 0.2), rgba(144, 204, 122, 0.25)), linear-gradient(152.53deg, #00588A -6.98%, rgba(0, 0, 0, 0) 143.37%), linear-gradient(360deg, rgba(173, 214, 158, 0.3) 47.34%, rgba(146, 207, 123, 0) 72.81%);
    #background: linear-gradient(0deg, rgba(111, 178, 216, 0.9) 0%, rgba(88, 103, 112, 0) 39.9%), linear-gradient(0deg, rgba(144, 204, 122, 0.25), rgba(144, 204, 122, 0.25)), linear-gradient(152.53deg, rgba(0, 88, 138, 0.5) -6.98%, rgba(0, 0, 0, 0) 143.37%), linear-gradient(152.53deg, #00588A -6.98%, rgba(0, 0, 0, 0) 143.37%), linear-gradient(360deg, rgba(173, 214, 158, 0.35) 47.34%, rgba(146, 207, 123, 0) 72.81%);
    #background: linear-gradient(0deg, rgba(111, 178, 216, 0.8) 0%, rgba(88, 103, 112, 0) 39.9%), linear-gradient(0deg, rgba(144, 204, 122, 0.2), rgba(144, 204, 122, 0.2)), linear-gradient(152.53deg, #00588A -6.98%, rgba(0, 0, 0, 0) 143.37%), linear-gradient(360deg, rgba(173, 214, 158, 0.3) 47.34%, rgba(146, 207, 123, 0) 72.81%);
`;

const styles = {
  Container: {
    width: "100vw",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    overflow: "hidden",
  },
  FinalGradient: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
};
