import React from "react";

import Svg from "../ui/Svg";

export default function IconRightArrow({ width = 25, height = 25, fill = "white", ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.95508 7.26733L10.2286 7.26733" stroke="white" strokeWidth="1.09808" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.11914 9.35889L10.2286 7.40033L8.11914 5.44177" stroke="white" strokeWidth="1.09808" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.1824 7.39721C14.1824 3.86412 11.2314 0.999989 7.59117 0.999989C3.95097 0.999989 1 3.86412 1 7.39721C1 10.9303 3.95097 13.7944 7.59118 13.7944C11.2314 13.7944 14.1824 10.9303 14.1824 7.39721Z" stroke="white" strokeWidth="1.09808" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>

    );
}