import React from "react";

import Svg from "../ui/Svg";

export default function IconFeiFlat({ width = 25, height = 21, fill = "white", ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3036 20.9999H2.31351C0.886351 20.9999 0.0708306 19.5728 0.886352 18.3495L6.595 9.37878C7.00276 8.76714 7.6144 8.35938 8.42992 8.35938H16.1774C16.9929 8.35938 17.6045 8.76714 18.0123 9.37878L23.7209 18.3495C24.5365 19.5728 23.7209 20.9999 22.2938 20.9999H12.3036Z" fill={fill}/>
            <path d="M13.3229 0.611885L15.7695 4.28173C16.1772 5.09725 15.7695 6.11665 14.7501 6.11665H9.85693C8.83753 6.11665 8.42977 5.09725 8.83753 4.28173L11.2841 0.611885C11.8957 -0.203636 12.9151 -0.203636 13.3229 0.611885Z" fill={fill}/>
        </Svg>

    );
}