import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import FeiLanding from "../components/pages/FeiLanding/index";
import NotFound from "../components/pages/NotFound";
import WhitepaperPage from "../components/pages/Whitepaper";

export default function AppNavigator() {
    return (
        <Switch>
            <Route path="/whitepaper/:language" component={WhitepaperPage}/>
            <Route path="/whitepaper" component={WhitepaperPage}/>
            <Route path="/" exact component={FeiLanding}/>
            <Route component={NotFound}/>
        </Switch>
    );
}

// Docs: https://reactrouter.com/web/
// Good explainer on react router basics: https://dev.to/codepanda/intro-to-react-router-1ngn