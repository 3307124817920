export const colors = {
    black: "#000000",
    white: "#FFFFFF",
    purple: "#514BD9",
    green: "#1E8915",
    blue: {
        atlantis: "#2F5C75",
    },
    grey: {
        light: "#DADADA"
    },
    dark: {
        medium: "#272B41",
        dark: "#080C24"

    },
};

export const radii = {
    tiny: "4px",
    small: "8px",
    medium: "10px",
    large: "25px",
};

export const shadows = {
    primary: "5px 5px 20px 10px rgba(0,0,0,0.1)",
};

export const fontWeights = {
    light: "300",
    regular: "400",
    bold: "500",
    black: "600",
};

export const fonts = {
    primary: '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    primaryBold: '"Roboto Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    primaryMedium: '"Roboto Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    secondary: '"Space Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: '"Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    barlowSemiBold: "Barlow SemiBold",
    barlowMedium: "Barlow Medium",
    futura: "Futura",
};