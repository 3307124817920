import React, { useState, Fragment, useEffect } from "react";
import Flex from "../../ui/Flex";
import { TitleText, BodyText } from "../../ui/Text";
import IconMenu from "../../svgs/IconMenu";
import { MAX_PAGE_WIDTH, MIN_PAGE_MARGIN } from "../../../utils/constants";
import { PrimaryButton } from "../../ui/Button";
import Link from "../../ui/Link";
import styled from "styled-components";
import { fonts } from "../../../providers/theme/theme";
import IconRightArrow from "../../svgs/IconRightArrow";
import { governanceLinks, resourcesLinks, communityLinks } from "./FooterBlock";
import { openLink } from "../../../utils/analytics";

const menuOpenAnimationTiming = "0.3s";

export default function HeaderBlock() {
  const [menuOpen, _setMenuOpen] = useState(false);
  const [currentAnimation, setCurrentAnimation] = useState("");

  function setMenuOpen(setTo) {
    setCurrentAnimation(
      `${
        setTo ? "fadeBackgroundIn" : "fadeBackgroundOut"
      } ${menuOpenAnimationTiming} 1`
    );
    window.document.body.style.overflow = setTo ? "hidden" : "";
    _setMenuOpen(setTo);
  }

  return (
    <Flex {...styles.Header}>
      <Flex {...styles.HeaderContentAndBannerContainer}>
        <Flex {...styles.BannerContainer}>
          <BodyText
            {...styles.BannerText}
          >
            {"Genesis participants have the opportunity to participate in a class action lawsuit settlement. See if you qualify for settlement funds"}
            <Link
              {...styles.BannerText}
              marginLeft={"4px"}
              text={" here"}
              onClick={() => {
                window.open("https://www.feitribesecuritiessettlement.com/");
              }}
            />
          </BodyText>


        </Flex>
        <Flex {...styles.BannerContainer} backgroundColor="orange">
          <BodyText
            {...styles.BannerText}
          >
            {"Tribe DAO and Fei Protocol are no longer under development. Please withdraw any funds you may have in Tribe DAO-related contracts "}
            <Link
              {...styles.BannerText}
              marginLeft={"4px"}
              text={" here"}
              onClick={() => {
                window.open("https://tribedao.xyz/");
              }}
            />
          </BodyText>


        </Flex>
        <Flex {...styles.HeaderContentContainer}>
          <TitleText text={"Fei Protocol"} {...styles.PageHeaderText} />
          <LaunchAppButton
            {...styles.HideOnMobile}
            onClick={() => openLink("https://app.fei.money", "LAUNCH_APP_HEADER")}
          />
          <IconMenu {...styles.IconMenu} onClick={() => setMenuOpen(true)} />
        </Flex>
      </Flex>

      <MenuBar
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        currentAnimation={currentAnimation}
      />
    </Flex>
  );
}

export function LaunchAppButton(props) {
  return (
    <PrimaryButton
      text={"Launch App"}
      hoverGradientKind={"rainbow"}
      {...styles.LaunchAppButton}
      {...props}
    />
  );
}

function MenuBar({ menuOpen, setMenuOpen, currentAnimation }) {
  return (
    <Fragment>
      <MenuBackdrop
        animation={currentAnimation}
        pointerEvents={menuOpen ? "" : "none"}
        backgroundColor={menuOpen ? "rgba(255,255,255,0.6)" : "transparent"}
        onClick={menuOpen ? () => setMenuOpen(false) : () => {}}
        pointerEvents={menuOpen ? null : "none"}
        display={["none", "block"]}
      />
      <Flex
        {...styles.MenuBarContainer}
        transform={
          menuOpen ? "translate3d(0vw, 0, 6)" : "translate3d(-100vw, 0, 0)"
        }
        transition={`all ${menuOpenAnimationTiming}`}
      >
        <IconRightArrow
          onClick={() => setMenuOpen(false)}
          {...styles.IconRightArrow}
        />
        <TitleText
          text={"Resources"}
          {...styles.SectionHeaderText}
          marginTop={"0px"}
        />
        <GradientLine width={"82px"} />
        {resourcesLinks.map(({ text, url, trackingName }, i) => {
          return (
            <BodyText
              text={text}
              onClick={() => {
                openLink(url, `${trackingName}_HEADER`);
              }}
              {...styles.LinkText}
              key={i}
            />
          );
        })}
        <TitleText text={"Governance"} {...styles.SectionHeaderText} />
        <GradientLine width={"85px"} />
        {governanceLinks.map(({ text, url, trackingName }, i) => {
          return (
            <BodyText
              text={text}
              onClick={() => {
                openLink(url, `${trackingName}_HEADER`);
              }}
              {...styles.LinkText}
              key={i}
            />
          );
        })}
        <TitleText text={"Community"} {...styles.SectionHeaderText} />
        <GradientLine width={"120px"} />
        <Flex {...styles.IconRow} marginTop={["15px", "10px"]}>
          {communityLinks
            .slice(0, 3)
            .map(({ text, url, IconComponent, trackingName }, i) => {
              return (
                <Flex
                  {...styles.CommunityIconContainer}
                  onClick={() => {
                    openLink(url, `${trackingName}_HEADER`);
                  }}
                  key={i}
                >
                  {IconComponent}
                </Flex>
              );
            })}
        </Flex>
        <Flex {...styles.IconRow}>
          {communityLinks
            .slice(3, 6)
            .map(({ text, url, IconComponent, trackingName }, i) => {
              return (
                <Flex
                  {...styles.CommunityIconContainer}
                  onClick={() => {
                    openLink(url, `${trackingName}_HEADER`);
                  }}
                  key={i}
                >
                  {IconComponent}
                </Flex>
              );
            })}
        </Flex>
      </Flex>
    </Fragment>
  );
}

const MenuBackdrop = styled(Flex)`
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;

  @keyframes fadeBackgroundIn {
    from {
      background-color: transparent;
    }
    to {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  @keyframes fadeBackgroundOut {
    from {
      background-color: rgba(255, 255, 255, 0.6);
    }
    to {
      background-color: transparent;
    }
  }
`;

const GradientLine = styled(Flex)`
  background: radial-gradient(
    150.04% 907799.79% at 96.57% -150.04%,
    #4d6a6f 0%,
    #91b6aa 62.32%,
    #c7e0d8 100%
  );
  height: 0.83px;
  margin-top: 13px;
  margin-bottom: 15px;
`;

const styles = {
  Header: {
    width: "100vw",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 6,
  },
  HeaderContentAndBannerContainer: {
    flexDirection: "column",
    width: "100%",
  },
  BannerContainer: {
    width: "100%",
    height: ["110px", "40px"],
    backgroundColor: "red",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  BannerText: {
    fontWeight: "600"
  },
  HeaderContentContainer: {
    maxWidth: MAX_PAGE_WIDTH,
    height: "10vh",
    maxHeight: "100px",
    minHeight: "60px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: MIN_PAGE_MARGIN,
    marginRight: MIN_PAGE_MARGIN,
  },
  IconContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: ["30px", "108px"],
  },
  IconMenu: {
    cursor: "pointer",
    height: ["18px", "21px"],
    width: "auto",
  },
  HideOnMobile: {
    display: ["none", "block"],
  },
  PageHeaderText: {
    fontSize: ["22px", "25px"],
    lineHeight: "25px",
    fontFamily: fonts.primaryMedium,
    letterSpacing: "1.7px",
  },
  LaunchAppButton: {
    paddingLeft: ["13px", "16px"],
    paddingBottom: ["0px", "0px"],
  },
  MenuBarContainer: {
    position: "fixed",
    backgroundColor: "#4A727A",
    left: 0,
    top: 0,
    zIndex: 4,
    height: "100vh",
    width: ["100vw", "350px"],
    flexDirection: "column",
    paddingTop: "30px",
    paddingRight: MIN_PAGE_MARGIN,
    paddingLeft: MIN_PAGE_MARGIN,
  },
  SectionHeaderText: {
    color: "white",
    fontFamily: fonts.barlowSemiBold,
    letterSpacing: "0.05em",
    fontSize: "16.6px",
    lineHeight: 1,
    marginTop: ["40px", "50px"],
  },
  LinkText: {
    fontFamily: fonts.barlowMedium,
    letterSpacing: "0.05em",
    fontSize: "14px",
    lineHeight: 2,
    color: "#A8E2DB",
    cursor: "pointer",
    underlineOnHover: true,
  },
  IconRightArrow: {
    position: "absolute",
    top: "30px",
    right: "25px",
    cursor: "pointer",
    transform: "rotate(180deg)",
  },
  CommunityIconContainer: {
    width: "34px",
    height: "34px",
    borderRadius: "34px",
    backgroundColor: "#76A2AD",
    marginRight: "15px",
    marginBottom: "15px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  IconRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "150px",
  },
};
