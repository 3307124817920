import React, { createContext, useContext, useMemo, useState, useEffect, Fragment, useRef } from "react";
import Web3 from "web3";

import { useContracts } from "./contracts";
import ethConfig from "./config";
import useFeiProtocolWatcher from "./feiProtocolWatcher";


const {
    rpcUrl,
    networkName,
} = ethConfig;

const EthereumContext = createContext();
export const useEthereum = () => useContext(EthereumContext);

export default function EthereumProvider({ children }) {
    const [ethState, _setEthState] = useState({
        connectedChainId: null,
        alchemyProvider: null,
    });
    
    const stateRef = useRef(ethState);
    const setEthState = (newState) => {
        stateRef.current = newState;
        _setEthState(newState);
    }

    const {
        connectedChainId,
        alchemyProvider,
    } = ethState;

    const {
        contracts,
    } = useContracts({
        connectedChainId,
        alchemyProvider,
    });

    const {
        protocolState,
        recreateWatcher,
    } = useFeiProtocolWatcher({
        contracts,
    });

    const setupEthereum = async () => {

        const web3AlchemyProvider = new Web3(rpcUrl);

        setEthState({
            ...stateRef.current,
            alchemyProvider: web3AlchemyProvider,
        });

    };

    // On setup: connect to wallet, get selected address and setup event listeners
    useEffect(() => {
        setupEthereum();
    }, []);

    // const value = useMemo(() => {
    //     console.log("ps2", protocolState)
    //     return {
    //         contracts,
    //         protocolState: protocolState,
    //         loading: !contracts,
    //         connectedChainId,
    //     };
    // }, [ethState, contracts, protocolState]);

    const value = {
            contracts,
            protocolState: protocolState,
            loading: !contracts,
            connectedChainId,
    };

    return (
        <Fragment>
            <EthereumContext.Provider value={value}>
                {children}
            </EthereumContext.Provider>
        </Fragment>
    );
}