import development from "./development";
import production from "./production";
import shared from "./shared";

const configByEnvironment = {
    development,
    production,
};

export const environment = process.env.REACT_ENVIRONMENT || "development";

const config = configByEnvironment[environment];

export default {
    ...shared,
    ...config,
};
