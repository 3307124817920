import React from "react";
import styled from "styled-components";
import { styles as textStyles } from "./Text";

import {
    borderColor,
    borders,
    color,
    display,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    opacity,
    space,
    textAlign,
    layout,
} from "styled-system";

import {
    hoverBg,
    hoverColor,
    hoverOpacity,
    cursor,
} from "../../providers/theme/customProperties";
import { colors, fonts } from "../../providers/theme/theme";

export const LinkElement = styled.a`
  ${borders};
  ${borderColor};
  ${color};
  ${cursor};
  ${display};
  ${fontFamily};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${opacity};
  ${space};
  ${textAlign};
  ${layout}

  text-decoration: none;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;

  border-bottom: ${props => (props.hasUnderline ? "1px solid white" : null)};

  @media (pointer: fine) {
    &:hover {
      ${hoverBg};
      ${hoverColor};
      ${hoverOpacity};
      border-bottom: ${props => (props.hasHoverUnderline ? `3px solid blue` : null)};
    }
  }
`;

function Link({
    children,
    onClick,
    text,
    ...props
}) {
    return (
        <LinkElement
            onClick={e => {
                onClick && onClick(e);
            }}
            {...styles.Base}
            {...props}
        >
            {children || text}
        </LinkElement>
    );
}

const styles = {
    Base: {
        ...textStyles.BodyText,
        hoverColor: colors.white,
        hasUnderline: true,
        cursor: "pointer",
    },
}



export default React.memo(Link);