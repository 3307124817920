import React from "react";
import styled, { css } from "styled-components";
import {
    color,
    layout,
    position,
    space,
    typography,
} from "styled-system";
import {
    hoverColor,
} from "../../../providers/theme/customProperties";
import { colors, fontWeights, fonts } from "../../../providers/theme/theme";

export const Text = React.memo(styled.div`
  ${color};
  ${layout};
  ${position};
  ${space};
  ${typography};
  white-space: pre-line;

  &:hover {
    ${hoverColor};
  }

  ${props =>
        props.truncated &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
 
  ${props =>
        props.cursor === "pointer" &&
        css`
        cursor: pointer;
   `};

   ${props => props.underlineOnHover && css`
      &:hover {
        text-decoration: underline;
      }
   `}

   ${props =>
    props.gradientKind === "rainbow" &&
    css`
        background: linear-gradient(90deg, #A7E2DB 30.2%, rgba(179, 226, 167, 0.96) 57.12%, rgba(208, 216, 176, 0.66) 83.02%), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    `
};
`);

export const HeaderText = React.memo(({text, children, ...props}) => {
  return (
    <Text {...styles.HeaderText} {...props}>
      {text || children}
    </Text>
  );
});

export const TitleText = React.memo(({text, children, ...props}) => {
  return (
    <Text {...styles.TitleText} {...props}>
      {text || children}
    </Text>
  );
});


export const BodyText = React.memo(({text, children, ...props}) => {
  return (
    <Text {...styles.BodyText} {...props}>
      {text || children}
    </Text>
  );
});

export const ColoredSpan = React.memo(styled.span`
    color: ${props => props.color};'
`);

export const styles = {
  HeaderText: {
    fontSize: ["20px", "30px"],
    lineHeight:["24px", "36px"],
    color: colors.white,
    fontWeight: fontWeights.bold,
    fontFamily: fonts.primaryBold,
  },
  TitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    color: colors.white,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.primary,
  },
  BodyText: {
    fontSize: "15px",
    lineHeight: "18px",
    color: colors.white,
    fontWeight: fontWeights.regular,
    fontFamily: fonts.barlowMedium,
  },
}