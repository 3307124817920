import styled from "styled-components";

import {
    color,
    display,
    height,
    maxWidth,
    position,
    right,
    space,
    top,
    width,
} from "styled-system";

import {
    cursor,
    fill,
    hoverColor,
    hoverFill,
    hoverOpacity,
    stroke,
    transition,
    transform,
} from "../../providers/theme/customProperties";

const Svg = styled.svg`
  ${color};
  ${cursor};
  ${display};
  ${fill};
  ${height};
  ${maxWidth};
  ${position};
  ${right};
  ${space};
  ${stroke};
  ${top};
  ${transition};
  ${transform};
  ${width};

  &:hover {
    ${hoverFill};
    ${hoverColor};
    ${hoverOpacity};
  }
`;

Svg.defaultProps = {
    fill: "currentColor",
    display: "block",
};

export default Svg;
