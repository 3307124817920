import React from "react";
import Flex from "../../ui/Flex";
import { ColoredSpan, TitleText, BodyText } from "../../ui/Text";

import { MAX_PAGE_WIDTH } from "../../../utils/constants";
import { PrimaryButton } from "../../ui/Button";
import { fonts } from "../../../providers/theme/theme";
import IconMiddleDrops from "../../svgs/IconMiddleDrops";
import tribeMonolithSrc from "../../../assets/images/tribe_monolith.png";
import Image from "../../ui/Image";
import PyramidTentaclesWeb from "../../svgs/PyramidTentaclesWeb";
import IconOndo from "../../svgs/IconOndo";
import IconBalancer from "../../svgs/IconBalancer";
import IconRari from "../../svgs/IconRari";
import PyramidTentaclesMobile from "../../svgs/PyramidTentaclesMobile";
import { openLink } from "../../../utils/analytics";
import config from "../../../config";
import IslandShadow from "../../svgs/IslandShadow";


export default function LaasBlock() {
    return (
        <Flex {...styles.Container}>
            <Flex {...styles.WhiteCardContainer}>
                <Flex {...styles.WhiteCardLeftContainer}>
                    <Flex {...styles.WhiteCardTextContainer}>
                        <TitleText {...styles.WhiteCardTitleText}>
                            Liquidity for <ColoredSpan color={"#709186"}>DAOs</ColoredSpan>
                        </TitleText>
                        <BodyText {...styles.WhiteCardBodyText}>
                            Fei Protocol helps other DAOs achieve deep liquidity in lending and exchange markets
                            {" "}<ColoredSpan color={"#79A38D"}>by deploying PCV and offering Liquidity-as-a-Service (LaaS)</ColoredSpan>
                        </BodyText>
                    </Flex>
                </Flex>
                <IconMiddleDrops
                    {...styles.OnlyWeb}
                />
                <Flex {...styles.MonolithImageContainer}>
                    <Image
                        src={tribeMonolithSrc}
                        {...styles.TribeMonolithImage}
                    />
                    <IslandShadow
                        {...styles.IslandShadow}
                    />
                </Flex>
            </Flex>
            <PrimaryButton
                text={"LaaS"}
                hoverGradientKind={"rainbow"}
                onClick={() => openLink(config.links.laas, "LAAS_BLOGPOST") }
                {...styles.LaasButton}
                {...styles.OnlyWeb}
            />
            <Flex {...styles.LaaSContainer}>
                <PyramidTentaclesWeb
                    {...styles.OnlyWeb}
                    {...styles.PyramidTentaclesWebImage}
                />
                <PyramidTentaclesMobile
                    {...styles.OnlyMobile}
                    {...styles.PyramidTentaclesMobileImage}
                />
                <Flex {...styles.LaasExamplesContainer}>
                    <IconOndo {...styles.IntegrationIcon}/>
                    <BodyText {...styles.LaasExampleBodyText} maxWidth={["290px", "360px"]}>
                        <ColoredSpan color={"#A7E2DB"}>Fei </ColoredSpan>integrates with <ColoredSpan color={"#A7E2DB"}>Ondo</ColoredSpan> to provide FEI-denominated Liquidity as a Service for other DAOs
                    </BodyText>
                    <IconRari {...styles.IntegrationIcon}/>
                    <BodyText {...styles.LaasExampleBodyText} maxWidth={["300px", "370px"]}>
                        <ColoredSpan color={"#A7E2DB"}>Fei DAO</ColoredSpan> supplies FEI to lending markets on <ColoredSpan color={"#A7E2DB"}>Rari Capital's Fuse</ColoredSpan> and across DeFi, bootstrapping markets and increasing FEI liquidity
                    </BodyText>
                    <IconBalancer {...styles.IntegrationIcon}/>
                    <BodyText {...styles.LaasExampleBodyText} maxWidth={["290px", "360px"]}>
                        <ColoredSpan color={"#A7E2DB"}>Fei </ColoredSpan>v2 deploys PCV on <ColoredSpan color={"#A7E2DB"}>Balancer</ColoredSpan> to simultaneously provide liquidity and earn yield
                    </BodyText>
                </Flex>
            </Flex>
        </Flex>
    );
}

const styles = {
    Container: {
        marginTop: "180px",
        width: "100%",
        maxWidth: MAX_PAGE_WIDTH,
        flexDirection: "column",
        alignItems: "center",
    },
    WhiteCardContainer: {
        backgroundColor: "#F5F5F5",
        width: "100%",
        maxWidth: MAX_PAGE_WIDTH,
        height: ["450px", "380px"],
        borderRadius: "10px",
        flexDirection: ["column", "row"],
        alignItems: "center",
        justifyContent: "space-evenly",
        boxShadow: "-6.66667px 0px 16.6667px rgba(0, 0, 0, 0.25)",
        overflow: "hidden"
    },
    WhiteCardLeftContainer: {
        width: ["auto", "48%"],
        alignItems: "center",
        justifyContent: "center"
    },
    WhiteCardTextContainer: {
        flexDirection: "column",
        alignItems: ["center", "flex-start"],
        justifyContent: "center",
        paddingLeft: ["20px", "40px"],
        paddingRight: ["20px", "40px"],
        
    },
    WhiteCardTitleText: {
        fontSize: ["22px", "35px"],
        fontFamily: fonts.primaryBold,
        lineHeight: "1",
        letterSpacing: "0.8px",
        color: "#4D6A6F",
    },
    WhiteCardBodyText: {
        fontFamily: fonts.barlowMedium,
        lineHeight: "1.66",
        fontSize: ["16px", "25px"],
        textAlign: ["center", "left"],
        letterSpacing: "0.2px",
        color: "#4B686F",
        maxWidth: ["290px", "380px"],
        marginTop: ["20px", "10px"],
    },
    TribeMonolithImage: {
        width: ["250px", "350px"],
        height: ["auto", "282px"],
        zIndex: 2,
        // display: "none"
    },
    MonolithImageContainer: {
        alignItems: "center",
        justifyContent: "center",
        width: ["auto", "48%"],
        position: "relative",
    },
    LaasButton: {
        marginTop: "90px",
        width: "90px"
    },
    LaaSContainer: {
        flexDirection: ["column", "row"],
        alignItems: "center",
        marginTop: "40px",
        position: "relative"
    },
    DropsSvg: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    OnlyWeb: {
        display: ["none", "block"]
    },
    OnlyMobile: {
        display: ["block", "none"]
    },
    LaasExamplesContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: ["400px", "0px"]
    },
    LaasExampleBodyText: {
        maxWidth: ["260px", "325px"],
        lineHeight: "1.66",
        fontSize: ["19px", "23.3px"],
        textAlign: ["center", "left"],
        marginTop: "20px",
        fontFamily: fonts.barlowMedium,
    },
    PyramidTentaclesWebImage: {
        marginTop: "120px",
        marginRight: "20px"
    },
    IntegrationIcon: {
        marginTop: ["50px", "70px"],
    },
    PyramidTentaclesMobileImage: {
        position: "absolute",
        width: "100vw",
        left: "50%",
        transform: "translate(-50%,0)", 
    },
    IslandShadow: {
        position: "absolute",
        top: "100%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 1,
        // display: "none"
    },
};