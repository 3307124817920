import React, {Suspense} from "react";
import { BrowserRouter } from "react-router-dom"
import AppNavigator from "./navigation/AppNavigator";
import EthereumProvider from "./providers/ethereum";
import ApolloProvider from "./providers/apollo";
import LoadingPage from "./components/pages/Loading";
import "./index.css";

function App() {
  return (
    <ApolloProvider>
      <EthereumProvider>
        <Suspense fallback={<LoadingPage/>}>
          <BrowserRouter>
              <AppNavigator/>
          </BrowserRouter>
        </Suspense>
      </EthereumProvider>
    </ApolloProvider>
  );
}

export default App;
