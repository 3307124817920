import React, { Fragment } from "react";
import Flex from "../../ui/Flex";
import { ColoredSpan, HeaderText, TitleText, BodyText } from "../../ui/Text";
import { MAX_PAGE_WIDTH, MIN_PAGE_MARGIN } from "../../../utils/constants";
import floatingCitySrc from "../../../assets/images/floating_city_v2.png";
import floatingCityShadowSrc from "../../../assets/images/city_shadow.png";
import cloudsSrc from "../../../assets/images/clouds.png";
import Image from "../../ui/Image";
import { fonts } from "../../../providers/theme/theme";
import { LaunchAppButton } from "./HeaderBlock";
import styled from "styled-components";
import { openLink } from "../../../utils/analytics";
import config from "../../../config";

export default function HeroBlock({ tvlFormatted }) {
  return (
    <Flex {...styles.Container}>
      <Flex {...styles.TopTextContainer}>
        <HeaderText {...styles.SloganText}>
          FEI is backed 1:1 by the DAI stablecoin
        </HeaderText>
        <LaunchAppButton
          onClick={() => openLink(config.links.app)}
          {...styles.LaunchAppButton}
          {...styles.HideOnWeb}
        />
      </Flex>
      <Flex {...styles.ImageContainer}>
        <Image src={floatingCitySrc} {...styles.FloatingCityImage} />
        <Image src={floatingCityShadowSrc} {...styles.ShadowImage} />
        <LightCircle {...styles.LightCircle} />
        <Image src={cloudsSrc} {...styles.CloudImage} />
      </Flex>
      {/* <BodyText
        text={
          "Fei is a decentralized, scalable, and DeFi-native stablecoin protocol"
        }
        {...styles.SubtitleText}
        {...styles.HideOnMobile}
      /> */}
    </Flex>
  );
}

function ProtocolControlledValueBlock({ tvlFormatted, ...props }) {
  if (!tvlFormatted) {
    return <Fragment />;
  }
  return (
    <Flex {...styles.PCVTextContainer}>
      <HeaderText
        gradientKind={"rainbow"}
        {...styles.TotalValueLockedText}
        {...props}
      >
        ${tvlFormatted}
      </HeaderText>
      <TitleText
        text={"Protocol Controlled Value"}
        {...styles.PCVText}
        {...props}
      />
    </Flex>
  );
}

const LightCircle = styled(Flex)`
  background: radial-gradient(
    112.94% 112.94% at 50% 50%,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 28.12%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(83.3333px);
`;

const styles = {
  Container: {
    width: "100vw",
    minHeight: "60vh",
    maxHeight: ["100vh", "1600px"],
    maxWidth: MAX_PAGE_WIDTH,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: ["space-evenly", "space-evenly"],
    paddingLeft: MIN_PAGE_MARGIN,
    paddingRight: MIN_PAGE_MARGIN,
  },
  TopTextContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ["5vh", "20px"],
    zIndex: 1,
  },
  PCVTextContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ["5vh", "0px"],
    marginBottom: ["5vh", "0px"],
  },
  SloganText: {
    fontWeight: "700",
    letterSpacing: "1.4px",
    fontSize: ["24px", "34px"],
    lineHeight: "40px",
    textAlign: "center",
  },
  TotalValueLockedText: {
    fontSize: ["40px", "73px"],
    fontFamily: fonts.futura,
    lineHeight: "120%",
    marginTop: "15px",
    letterSpacing: "2.2px",
    zIndex: 4,
    maxWidth: "95vw",
  },
  PCVText: {
    color: "#A7E2DB",
    fontFamily: fonts.primaryMedium,
    fontSize: ["20px", "23px"],
    marginTop: "10px",
    letterSpacing: "1.2px",
    zIndex: 4,
  },
  ImageContainer: {
    position: "relative",
    zIndex: 0,
    marginTop: ["2vh", "0px"],
  },
  FloatingCityImage: {
    width: ["90vw", "750px"],
    zIndex: 3,
    marginTop: "15px",
  },
  ShadowImage: {
    position: "absolute",
    zIndex: 2,
    bottom: ["-160px", "-230px"],
    left: "50%",
    transform: "translate(-50%,0)",
    width: "200%",
  },
  LightCircle: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-58%,-52%)",
    width: "1000px",
    height: "1000px",
    zIndex: 1,
  },
  CloudImage: {
    position: "absolute",
    width: ["140vw", "90vw"],
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-40%)",
    zIndex: 1,
  },
  SubtitleText: {
    lineHeight: "166%",
    fontSize: "24px",
    maxWidth: "480px",
    textAlign: "center",
    fontWeight: "700",
    letterSpacing: "1.5px",
    fontFamily: fonts.barlowSemiBold,
    zIndex: 4,
    marginTop: "-2q0px",
  },
  HideOnMobile: {
    display: ["none", "block"],
  },
  HideOnWeb: {
    display: ["block", "none"],
  },
  LaunchAppButton: {
    marginTop: "30px",
  },
};
