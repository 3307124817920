import React, {useEffect} from "react";
import Flex from "../ui/Flex";
import { HeaderText } from "../ui/Text";
import { initAmplitude, sendAmplitudeData } from "../../utils/analytics";
import { colors } from "../../providers/theme/theme";
import { SolarPunkGradientBackground } from "./FeiLanding";

export default function NotFound() {
    useEffect(() => {
        initAmplitude();
        sendAmplitudeData("VIEWED_404_PAGE");
    }, []);
    return (
        <SolarPunkGradientBackground {...styles.Container}>
            <HeaderText text={"Page Not Found"}/>
        </SolarPunkGradientBackground>
    );
}

const styles = {
    Container: {
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
    },
};