import config from "../../../config";
import { useEffect, useState } from "react";


export const contractsConfig = [
    {
        name: "CollateralizationOracle",
        abi: [],
        addresses: {
            MAINNET: "0xd1866289B4Bd22D453fFF676760961e0898EE9BF"
        }
    },
].map(({name, abi, addresses}) => {
    return {
        name,
        abi,
        address: addresses[config.network],
    };
}).filter(({address}) => {
    return !!address;
});

export function useContracts({
    connectedChainId,
    alchemyProvider,
}) {

    const [contracts, setContracts] = useState(null);
    // We are going to be submitting transactions with this contracts
    // instance so lets use the wallet provider if available
    const web3Provider = alchemyProvider;

    useEffect(() => {
        updateContracts();
    }, [
        connectedChainId,
        web3Provider,
    ]);

    const updateContracts = async () => {
        if (!web3Provider) {
            return;
        }
        const newContracts = {};
        for (let i = 0; i < contractsConfig.length; i++) {
            let {
                name,
                abi,
                address,
            } = contractsConfig[i];
            const contractOptions = {};
            newContracts[name] = await new web3Provider.eth.Contract(abi, address, contractOptions);
        }
        setContracts(newContracts);
    }
    
    return {
        contracts,
    };
}