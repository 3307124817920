export default {
    links: {
        discord: "https://discord.gg/2prhYdQ5jP",
        telegram: "https://t.me/feiprotocol",
        twitter: "https://twitter.com/feiprotocol",
        github: "https://github.com/fei-protocol/fei-protocol-core",
        docs: "https://docs.fei.money",
        discourse: "https://tribe.fei.money",
        tally: "https://www.tally.xyz/governance/fei",
        app: "https://app.fei.money",
        snapshot: "https://snapshot.fei.money",
        grants: "https://aromatic-grouse-e85.notion.site/Fei-Grants-Program-9373df863bb54b389ebcd516780d7975",
        brandAssets: "https://github.com/fei-protocol/brand-kit",
        analyticsPage: "https://app.fei.money/analytics",
        redeemPage: "https://app.fei.money/redeem",
        laas: "https://medium.com/fei-protocol/if-you-are-part-of-a-dao-or-protocol-that-wants-to-create-liquidity-for-your-token-without-f49a01f02863",
        medium: {
            base: "https://medium.com/fei-protocol",
            initialAnnouncement: "https://medium.com/fei-protocol/introducing-fei-protocol-2db79bd7a82b",
            genesisLaunchAnnouncement: "https://medium.com/fei-protocol/fei-protocol-genesis-group-d6cf1d266139",
        },
        whitepaper: {
            english: "https://assets.fei.money/docs/whitepaper.pdf",
            chinese: "https://assets.fei.money/docs/whitepaper_CN.pdf",
            german: "https://assets.fei.money/docs/whitepaper_GER.pdf",
            russian: "https://assets.fei.money/docs/whitepaper_RUS.pdf",
            french: "https://assets.fei.money/docs/whitepaper_FR.pdf",
        }
    },
    network: "MAINNET"
}