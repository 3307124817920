import React from "react";
import styled, { css }  from "styled-components";
import Loader from "../Loader";
import {colors, fonts, radii} from "../../../providers/theme/theme";

import {
    alignItems,
    borderRadius,
    boxShadow,
    color,
    display,
    fontFamily,
    fontSize,
    fontWeight,
    height,
    width,
    minHeight,
    minWidth,
    justifyContent,
    letterSpacing,
    lineHeight,
    position,
    space,
    
} from "styled-system";

import {
    fill,
    hoverBg,
    hoverBoxShadow,
    hoverColor,
    hoverFill,
    pointerEvents,
    textTransform,
} from "../../../providers/theme/customProperties";
import { sendAmplitudeData } from "../../../utils/analytics";

const ButtonElement = styled.button`
    ${alignItems};
    ${borderRadius};
    ${boxShadow};
    ${color};
    ${display};
    ${fill};
    ${fontFamily};
    ${fontSize};
    ${fontWeight};
    ${height};
    ${justifyContent};
    ${letterSpacing};
    ${lineHeight};
    ${minHeight};
    ${minWidth};
    ${pointerEvents};
    ${position};
    ${space};
    ${textTransform};
    ${width};
    ${fill};

    appearance: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    outline-style: none;
    text-align: center;
    text-decoration: none;
    transition-duration: 200ms;
    user-select: none;
    white-space: nowrap;
    
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    @media (pointer: fine) {
        &:hover {
            ${hoverBg};
            ${hoverBoxShadow};
            ${hoverColor};
            ${hoverFill};

            #transform: scale(1.01);
        }

        &:active {
            ${boxShadow};
            transform: translateY(0);
        }
    }

    ${props =>
        props.hoverGradientKind === "rainbow" &&
        css`
            @media (pointer: fine) {
                &:hover {
                    background: ${rainbowGradient};
                }
            }
        `
    };

    ${props =>
        props.gradientKind === "rainbow" &&
        css`
            background: ${rainbowGradient};
        `
    };

    ${props =>
        props.gradientKind === "blue" &&
        css`
            background: linear-gradient(
                0deg,
                #178DD0,
                #178DD0
            );
        `
    };

    ${props =>
        props.gradientKind === "green" &&
        css`
            background: linear-gradient(
                0deg,
                #25B882,
                #25B882
            );
        `
    };

    ${props =>
        props.gradientKind === "purple" &&
        css`
            background: linear-gradient(
                0deg,
                #7289DA,
                #7289DA
            );
        `
    };
    
`;

const oldRainbowGradient = "linear-gradient(90deg, #A7E2DB 30.2%, rgba(179, 226, 167, 0.96) 57.12%, rgba(208, 216, 176, 0.66) 83.02%), linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9))"
const rainbowGradient = "linear-gradient(90deg, #A7E2DB 29.68%, rgba(179, 226, 167, 0.96) 76.8%, rgba(208, 216, 176, 0.96) 103.54%), linear-gradient(270deg, #6B9CAC 42.38%, rgba(107, 156, 172, 0.891284) 73.25%, rgba(107, 156, 172, 0.576507) 100.57%, rgba(107, 156, 172, 0) 110.06%)";

function Button({ 
    loading, 
    disabled, 
    children, 
    onClick,
    trackingName,
    ...props }) {

    const disabledProps = (disabled || loading) ? {
        backgroundColor: colors.gray,
        hoverBg: colors.gray,
    } : {};

    const buttonContent = loading ? (
        <Loader 
            height="20px" 
            width="50px" 
            type="ThreeDots"
            color="rgba(255,255,255,0.5)"
        />
    ) : children;

    return (
        <ButtonElement
            disabled={disabled || loading}
            onClick={(e) => {
                trackingName && sendAmplitudeData(`CLICKED_${trackingName}_BUTTON`)
                onClick && onClick(e);
            }}
            {...styles.Base}
            {...props}
            {...disabledProps}
        >
            {buttonContent}
        </ButtonElement>
    );
}
export const PrimaryButton = React.memo(({
    text,
    children,
    ...props
}) => {
    return (
        <Button {...styles.Primary} {...props}>
            {text || children}
        </Button>
    );
});

const styles = {
    Base: {
        lineHeight: 1,
    },
    Primary: {
        bg: colors.white,
        color: "#205765",
        hoverBg: colors.purple,
        hoverColor: "#205765",
        hoverBoxShadow: "button.hover",
        borderRadius: "20px",
        height: ["29px", "39px"],
        minHeight: ["22px", "27px"],
        fontSize: ["18px", "22px"],
        fontFamily: fonts.primaryBold,
        pl: ["10px", "15px"],
        pr: ["10px", "15px"],
        pt: ["2px", null]
    }
};
export default React.memo(Button);

