import React from "react";

import Svg from "../ui/Svg";

export default function IconTribeFlat({ width = null, height = 21, ...props }) {
    return (
        <Svg width={width} height={height} {...props} viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.281268 12.7448C0.370199 12.0042 0.414664 11.6339 0.733277 11.3785C1.05189 11.123 1.42133 11.1599 2.16021 11.2337C2.60677 11.2783 3.14946 11.3407 3.79407 11.4279C4.39959 11.5098 4.93316 11.6515 5.38997 11.8152C6.06722 12.058 6.40584 12.1793 6.86149 12.8267C7.31714 13.474 7.31714 14.0121 7.31714 15.0881C7.31714 15.9074 7.31714 17.0002 7.31714 18.4387C7.31714 20.3422 7.31714 21.6405 7.31714 22.5005C7.31714 23.2071 7.31714 23.5604 7.09747 23.7801C6.8778 23.9997 6.52435 23.9997 5.81746 23.9997C5.12856 23.9997 4.29273 23.9997 3.51223 23.9997C3.3199 23.9997 3.13393 23.9996 2.95523 23.9993C1.79318 23.9974 1.21216 23.9965 0.784253 23.608C0.356348 23.2195 0.302471 22.672 0.194717 21.577C0.089665 20.5094 0 19.1366 0 17.5607C0 15.5613 0.144308 13.8853 0.281268 12.7448Z" fill="white"/>
            <path d="M19.9165 12.7448C19.8276 12.0042 19.7831 11.6339 19.4645 11.3785C19.1459 11.123 18.7764 11.1599 18.0375 11.2337C17.591 11.2783 17.0483 11.3407 16.4037 11.4279C15.7982 11.5098 15.2646 11.6515 14.8078 11.8152C14.1305 12.058 13.7919 12.1793 13.3363 12.8267C12.8806 13.474 12.8806 14.0121 12.8806 15.0881C12.8806 15.9074 12.8806 17.0002 12.8806 18.4387C12.8806 20.3422 12.8806 21.6405 12.8806 22.5005C12.8806 23.2071 12.8806 23.5604 13.1003 23.7801C13.32 23.9997 13.6734 23.9997 14.3803 23.9997C15.0692 23.9997 15.905 23.9997 16.6855 23.9997C16.8779 23.9997 17.0638 23.9996 17.2425 23.9993C18.4046 23.9974 18.9856 23.9965 19.4135 23.608C19.8414 23.2195 19.8953 22.672 20.003 21.577C20.1081 20.5094 20.1978 19.1366 20.1978 17.5607C20.1978 15.5613 20.0534 13.8853 19.9165 12.7448Z" fill="white"/>
            <path d="M0.293091 3.21289C0.293091 1.93871 0.293091 1.30163 0.692368 0.870339C1.09165 0.43905 1.71249 0.391121 2.95419 0.295263C4.80538 0.152354 7.4372 0 10.2444 0C13.0516 0 15.6834 0.152354 17.5346 0.295263C18.7763 0.391121 19.3971 0.43905 19.7964 0.870339C20.1957 1.30163 20.1957 1.93872 20.1957 3.21289V3.83343C20.1957 5.09758 20.1957 5.72966 19.7969 6.16083C19.3982 6.592 18.7851 6.63995 17.5588 6.73585C15.7568 6.87679 13.1705 7.02445 10.2444 7.02445C7.31826 7.02445 4.73204 6.87679 2.92997 6.73585C1.70374 6.63995 1.09062 6.592 0.691856 6.16083C0.293091 5.72966 0.293091 5.09758 0.293091 3.83342V3.21289Z" fill="white"/>
        </Svg>
    );
}