import React from "react";
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
} from "@apollo/client";

import config from "../../config";

const cache = new InMemoryCache({});

export const client = new ApolloClient({
    uri: config.apiUri,
    cache,
});

export default function Provider({children}) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}