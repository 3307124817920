import { useState, useEffect, useRef } from "react";
import { createWatcher } from "@makerdao/multicall";
import get from "lodash/get";
import set from "lodash/set";
import throttle from "lodash/throttle";
import config from "../../config";
import ethereumConfig from "./config";
// import {track} from "../../providers/analytics";
// import {EVENT_TYPES, CONTEXT_NAMES, EVENTS} from "../../providers/analytics/events";

const multicallContractAddresses = {
    LOCAL: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    ROPSTEN: "0x53c43764255c17bd724f74c4ef150724ac50a3ed",
    MAINNET: "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    RINKEBY: "0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821",
};

// Watcher timing params
const watcherInterval = 10 * 60 * 1000; // 10m
const secondsBeforeInactivity = 25; // This is lower than the watcher interval to make sure users who bounce don't do more than 1 eth_call
const activityMonitorThrottle = 1000; // Update lastUserActivityTimestamp max 1/second
const inactivityMonitorInterval = 10000; // How often we check for inactivity

const watcherConfig = {
    rpcUrl: ethereumConfig.rpcUrl,
    multicallAddress: multicallContractAddresses[config.network],
};

export default function useFeiProtocolWatcher({
    contracts,
}) {

    const [state, _setState] = useState({
        watcher: null,
        shouldWatch: true,
        protocolState: {},
        lastUserActivityTimestamp: Date.now(),
    });
    const stateRef = useRef(state);
    const setState = (newState) => {
        stateRef.current = newState;
        _setState(newState);
    }

    const {
        watcher,
        shouldWatch,
        protocolState,
    } = state;


    const watcherModel = getWatcherModel({
        contracts,
    });

    watcherConfig.interval = watcherInterval;

    const updateLastUserActivityTimestamp = throttle(() => {
        setState({
            ...stateRef.current,
            lastUserActivityTimestamp: Date.now(),
        });
        if (!stateRef.current.shouldWatch) {
            setState({
                ...stateRef.current,
                shouldWatch: true,
            })
        }     
    }, activityMonitorThrottle, {leading: true});

    useEffect(() => {
        const events = [
            "mousemove",
            "focus",
            "keypress",
            "click",
            "scroll",
        ];
        events.forEach((eventName) => {
            window.addEventListener(eventName, () => {
                updateLastUserActivityTimestamp();
            });
        });

        setInterval(() => {
            const secondsElapsed = (Date.now() - stateRef.current.lastUserActivityTimestamp) / 1000;
            if (secondsElapsed > secondsBeforeInactivity) {
                setState({
                    ...stateRef.current,
                    shouldWatch: false,
                })
            }
        }, inactivityMonitorInterval)
    }, []);

    useEffect(() => {
        if (!watcher) {
            return;
        }
        if (shouldWatch) {
            watcher.start();
        } else {
            watcher.stop();
        }
    }, [shouldWatch]);


    const recreateWatcher = () => {
        setState({
            ...stateRef.current,
            protocolState: {},
        });
        watcher.recreate(watcherModel, watcherConfig);
    }

    useEffect(() => {
        if (!contracts) {
            return;
        }

        if (!watcher) {
            const newWatcher = createWatcher(watcherModel, watcherConfig);
            setState({
                ...stateRef.current,
                watcher: newWatcher,
            });

            newWatcher.batch().subscribe(parseWatcherUpdates(setState, stateRef, contracts));

            newWatcher.onPoll(listener => {
                // track({
                //     eventName: EVENTS.GENERIC.WATCHER_WEB3_CALL,
                //     eventType: EVENT_TYPES.API_CALL,
                //     contextName: CONTEXT_NAMES.BACKGROUND,
                // }, listener);
            });

            newWatcher.onError(error => {
                console.log("Error watching Fei Protocol: ", error);
                // track({
                //     eventName: EVENTS.GENERIC.WATCHER_WEB3_ERROR,
                //     eventType: EVENT_TYPES.API_ERROR,
                //     contextName: CONTEXT_NAMES.BACKGROUND,
                // });
            });

            newWatcher.start();
        } else {
            // The watcher exists so just recreate it with the new model
            recreateWatcher();
        }
    }, [contracts]);
    
    return {
        protocolState,
        recreateWatcher,
    };
}

function parseWatcherUpdates(setState, stateRef, contracts) {
    return (updates) => {
        // We are going to ovewrite all of the old fields with new ones
        const newState = stateRef.current.protocolState;
        for (let i = 0; i < updates.length; i++) {
            let { type, value } = updates[i];
            set(newState, type, value);
        }
        setState({
            ...stateRef.current,
            protocolState: newState,
        });
    }
}

function getWatcherModel({
    contracts,
}) {

    // The base watcher model includes all the unconditional queries
    const baseWatcherModel = getBaseWatcherModel(
        contracts,
    ); 

    return baseWatcherModel
        .filter(({target}) => {
            // Remove calls without valid contract addresses
            return !!target;
        })
        .filter(({active}) => {
            // Remove calls labeled as not active
            return !!active;
        });

}

function getBaseWatcherModel(
    contracts,
) {
    return [
        {
            target: get(contracts, "CollateralizationOracle._address"),
            call: ["pcvStats()(uint256,uint256,uint256,bool)"],
            returns: [
                ["COLLATERALIZATION_ORACLE.PCV", val => val.toString()],
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
                ["SKIP", val => null], // ignore
            ],
            active: true,
        },
    ];
}